const cookieName        = "cookieNotification";
const cookieConsentName = "cookieConsent";
const allCookies        = [ cookieName, cookieConsentName ];

const now        = Date.now();
const cookieTime = now + 1000 * 60 * 60 * 24 * 180; // expire in 180 days (~6 months)
const cookieExp  = new Date(cookieTime);

const allDomains = [ "domainAnet", "domainGw2", "domainGw" ]; // these get rewritten at build time
const noSub      = location.host.split(".").slice(1).join("."); // eslint-disable-line newline-per-chained-call

/**
 * Filter list of domains to sync with, but must contain at least 2! (local, dev, stage all on same domain)
 * @param {array} domains - env specific, basically allDomains
 * @param {string} currDomain - current domain without subdomain
 * @returns {array} filtered list of domains to sync cookies with
 */
function getDomainsToSync(domains, currDomain) {
    let filtered = false;

    // can't do a simple filter as then NO domains will be left
    // to test on any env but live (as everything is on *.ncplatform.net)
    return domains.filter(domain => {
        if (filtered || currDomain !== domain.split(".").slice(1).join(".")) { // eslint-disable-line newline-per-chained-call
            return true;
        }

        filtered = true;

        return false;
    });
}

/**
 * Fetch timestamp from server, cookie notification must have been shown after that date
 * for when we have to force show cookie notification (if the Privacy Policy was updated)
 */
function getMinTimestamp() {
    return fetch("/cookie-notification")
        .then(res => res.json())
        .then(res => res.date)
        .catch(() => 0);
}

/**
 * Get the current domain (no sub domain)
 */
function getDomain() {
    const parts = document.location.hostname.split(".");

    return parts.length > 2 ?
        parts.splice(1).join(".") :
        document.location.hostname;
}

/**
 * Get a cookie by key
 */
function getCookie(key) {
    const re = new RegExp(`(?:(?:^|.*;\\s*)${key}\\s*\\=\\s*([^;]*).*$)|^.*$`);

    return document.cookie.replace(re, "$1");
}

/**
 * Set a cookie
 */
function setCookie({ key, value }) {
    document.cookie = `${key}=${value};expires=${cookieExp};path=/;domain=${getDomain()};SameSite=None;Secure`;
}

/**
 * Sync cookies from another domain
 * @param {string} domain - domain to sync cookies with
 * @param {array} cookieKeys - cookies to attempt to sync
 */
function syncCookies(domain, cookieKeys) {
    return fetch(`${domain}/cookie-sync?cookieKeys=${cookieKeys.join(",")}`, { credentials : "include" })
        .then(res => res.json())
        .then(cookies => {
            Object.entries(cookies).forEach(([ key, value ]) => setCookie({ key, value }));
        })
        .catch((error) => {
            console.log({ error, domain });

            return false;
        });
}

/**
 * check if the cookie is set for showing the notification and dated correctly
 * @param {number} minTimeStamp - timestamp to compare cookies against
 */
function getAndCheckCookie(minTimestamp) {
    const setTimestamp = parseInt(getCookie(cookieName), 10);

    return setTimestamp > minTimestamp;
}

/**
 * Check cookies to see if user agreed to cookie policy, and sync cookies from other domains
 */
function isCookieValid() {
    const domainsToSync = getDomainsToSync(allDomains, noSub);

    return getMinTimestamp()
        .then(minTimestamp => {
            // check cookies set on this domain, eg anet
            if (getAndCheckCookie(minTimestamp)) {
                return true;
            }

            // try cookies from another domain, eg gw2.com
            return syncCookies(domainsToSync[0], allCookies).then(() => {
                if (getAndCheckCookie(minTimestamp)) {
                    return true;
                }

                // try cookies from another domain, eg gw.com
                return syncCookies(domainsToSync[1], allCookies).then(() => getAndCheckCookie(minTimestamp));
            });
        });
}

/**
 * Parses {@link cookieConsentName} back into a numeric string.
 * @returns {string} "1" or "0"
 */
function parseCookieToSetting() {
    return getCookie(cookieConsentName) === "true" ? "1" : "0";
}

/**
 * For triggering tags in GTM if cookie consent is true
 */
function setDataLayer() {
    const cookieConsent = getCookie(cookieConsentName);

    if (cookieConsent === "true") {
        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push({ cookieConsent : true }); // for non-pageview events
        window.dataLayer.push({ event : "cookieConsent" }); // pageview

        if (window.gtag) {
            // gtag is a wrapper for dataLayer.push(arguments)
            // can't just push array to dataLayer.. has to be array-like object
            window.gtag("consent", "update", { ad_storage : "granted" }); // external ad cookies
        }
    }
}

/**
 * Google Analytics
 * @param {type} name - desc
 */
function ga(action, label) {
    const dataLayer = window.dataLayer || [];

    dataLayer.push({
        event         : "event",
        eventCategory : "CookieNotification",
        eventAction   : action,
        eventLabel    : label
    });
}

// Global Privacy Control Spec: https://globalprivacycontrol.org/#gpc-spec
// tl;dr if a browser has this setting TRUE, just opt them out of marketing cookies.
function syncGPC() {
    if (navigator && navigator.globalPrivacyControl) {
        setCookie({
            key   : cookieName,
            value : now
        });
        setCookie({
            key   : cookieConsentName,
            value : false
        });
    }
}

function startup({ CookieNotification }) {
    syncGPC();
    isCookieValid().then(valid => {
        const mount = document.createElement("div");

        document.body.appendChild(mount);

        new CookieNotification({
            target : mount,
            props  : {
                lang     : (document.documentElement.lang || "").split("-")[0] || "en",
                hide     : valid,
                settings : valid ? parseCookieToSetting() : undefined
            }
        });

        // cookie preference was previously confirmed
        if (valid) {
            setDataLayer();
            ga("dontShow");
        } else {
            ga("show");
        }
    });
}

export {
    startup,
    getMinTimestamp,
    getCookie,
    setCookie,
    isCookieValid,
    setDataLayer,
    ga,

    cookieConsentName,
    cookieName,
    now
};
