<script>
	import { fade } from 'svelte/transition';
    import strings from "./strings";
    import {
        setCookie, setDataLayer, ga,
        cookieName, cookieConsentName, now
    } from "./lib";

    export let lang;
    export let hide;
    export let settings;

    const i18n = strings[lang] || strings.en;

    let showSettings = false;

    function toggle() {
        showSettings = !showSettings;

        ga("toggleSettings", showSettings ? "open" : "close");
    }

    function accept() {
        hide = true;

        ga("accept", settings);

        setCookie({
            key   : cookieName,
            value : now
        });
        setCookie({
            key   : cookieConsentName,
            value : (settings === "1" || typeof settings === "undefined")
        });
        setDataLayer();
    }

    window.arenanetCookieNotification = {
        toggle: function(){
            hide = !hide;
        }
    };
</script>

<style lang="postcss">
    .scrim {
        --color-grey: #545454;

        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1500; /* anet dimebar smh */

        border-top: solid 4px #000;
        background: white;

        transition: opacity 0.3s;

        /* for some reason this isn't set for GW2.com */
        box-sizing: border-box;

        *,
        *:before,
        *:after {
            box-sizing: inherit;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        text-align: left;
        max-width: 1200px;

        padding: 30px 20px;

        font-family: CronosPro, Arial, Helvetica, sans-serif;
        color: #000;

        background: #FFF;

        @media screen and (min-width: 800px) {
            flex-direction: row;
        }

        h5 {
            margin: 0 0 0.6em;

            text-transform: uppercase;
            font-size: 32px;
            font-family: EasonPro, "Times New Roman", serif;
        }

        p {
            margin-bottom: 0.6em;

            color: #000;
            font-size: 15px;
            line-height: 1.5;
        }

        hr {
            display: none;
        }

        label {
            display: flex;
            margin-bottom: 10px;

            input {
                margin: 1px 10px 0 0;
            }
        }

        :global(a) {
            color: #000;
        }
    }

    .settings {
        display: flex;
    }

    .quaggan {
        display: none;

        width: 150px;
        margin-right: 30px;

        @media screen and (min-width: 800px) {
            display: block;
        }
    }

    .options {
        margin: 0 auto;
        text-align: left;
    }

    .actions {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media screen and (min-width: 800px) {
            margin-left: 30px;
        }
    }

    .cta {
        margin-top: 0.6em;
        padding: 1em 2em;

        text-transform: uppercase;
        font-family: EasonPro;
        font-size: 20px;

        white-space: nowrap;
        color: #FFF;

        border: none;
        background: linear-gradient(to bottom, #C62E2D 66%, #47100D) no-repeat 50% 100%;
        background-size: 100% 200%;

        cursor: pointer;
        transition: background 0.2s;

        &:focus,
        &:hover {
            background-position: 50% 0;
        }

        &:disabled {
            filter: saturate(0);
            cursor: not-allowed;
        }

        :lang(fr) &,
        :lang(de) & {
            white-space: unset;
            line-height: 1;
            padding-top: 0.65em;
            padding-bottom: 0.55em;
        }
    }

    .toggle {
        margin-top: 20px;

        color: var(--color-grey);
        font-size: 16px;

        background: none;
        border: none;

        span {
            text-decoration: underline;
        }

        &:after {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            content: "+";
            width: 1em;
            height: 1em;
            margin-left: 0.4em;

            line-height: 1;
            text-decoration: none;
            border-radius: 50%;
            border: solid 1px var(--color-grey);
        }

        &:focus {
            outline: none;
            background: var(--color-gray);
        }
    }
</style>

{#if !hide}
<div class="scrim" transition:fade={{ duration : 300 }}>
    <div class="content">

        {#if !showSettings}
            <div class="disclamier">
                <h5>{i18n.disclaimer.header}</h5>
                <p>{@html i18n.disclaimer.copy}</p>
            </div>
        {:else}
            <div class="settings">
                <img class="quaggan" src={settings === "1" ?
                "https://services.staticwars.com/services/2bumpers/cookie-notification-2/img/quaggan-happy.jpg" :
                "https://services.staticwars.com/services/2bumpers/cookie-notification-2/img/quaggan-sad.jpg"} alt=""/>

                <div>
                    <h5>{i18n.settings.header}</h5>
                    <p>{@html i18n.settings.copy}</p>

                    <div class="options">
                        {#each Object.entries(i18n.settings.options) as [key, option]}
                            <label for="settings{key}">
                                <input type="radio" value={key} id="settings{key}" name="settings" bind:group={settings}>
                                {option}
                            </label>
                        {/each}
                    </div>
                </div>
            </div>
        {/if}

        <div class="actions">
            <button class="accept cta" disabled={showSettings && typeof settings === "undefined"} on:click={accept}>
                {showSettings ? i18n.settings.button : i18n.disclaimer.button}
            </button>

            <hr>

            <button class="toggle" on:click={toggle}><span>{i18n.toggle}</span></button>
        </div>
    </div>
</div>
{/if}
