export default {
    en : {
        disclaimer : {
            header : "Cookie Policy",
            copy   : "By clicking “I Accept” below, you accept our and third parties’ use of cookies designed to help target personalized advertising, and content on our websites and those of others and related personal data processing. You may change your cookie preferences by visiting “Cookie Settings,” below. See our <a href='https://arena.net/legal/privacy-policy'>ArenaNet Privacy Policy</a> for more information about our privacy practices.",
            button : "I Accept",
        },
        settings : {
            header  : "Cookie Settings",
            copy    : "Please select one of the two options below and press the “Save Settings” button to confirm your settings.",
            options : {
                0 : "All marketing, social media, and advertising cookies off",
                1 : "All cookies activated"
            },
            button : "Save Settings"
        },
        toggle : "Cookie Settings"
    },
    de : {
        disclaimer : {
            header : "Cookie-Richtlinie",
            copy   : "Wenn du unten auf „Ich stimme zu“ klickst, akzeptierst du, dass wir und unsere Partner Cookies zum Anbieten personalisierter Werbung und Inhalte auf unseren und anderen Websites einsetzen, sowie die damit zusammenhängende Verarbeitung persönlicher Daten. Du kannst deine Cookie-Einstellungen in den „Cookie-Einstellungen“ weiter unten ändern. Unter <a href='https://arena.net/de/legal/privacy-policy'>unserer ArenaNet-Datenschutzrichtlinie</a> erfährst du weitere Einzelheiten zu unseren Datenschutzpraktiken.",
            button : "Ich stimme zu",
        },
        settings : {
            header  : "Cookie-Einstellungen",
            copy    : "Bitte wähle eine der folgenden Optionen und drücke auf „Einstellungen speichern“, um deine Einstellungen zu bestätigen.",
            options : {
                0 : "Alle Cookies für Marketing, soziale Medien und Werbung AUS",
                1 : "Alle Cookies aktiviert"
            },
            button : "Einstellungen speichern"
        },
        toggle : "Cookie-Einstellungen"
    },
    es : {
        disclaimer : {
            header : "Política de cookies",
            copy   : "Al hacer clic en «Acepto», aceptas el uso que nosotros y otros terceros hagan de las cookies, creadas para ofrecer publicidad personalizada, y el contenido de nuestros sitios web y de terceros, además del procesamiento de datos personales relacionados. Ve a «Ajustes de cookies» para modificar tus preferencias. Para más información sobre nuestras prácticas de privacidad, consulta <a href='https://arena.net/es/legal/privacy-policy'>la política de privacidad de ArenaNet</a>.",
            button : "Acepto",
        },
        settings : {
            header  : "Ajustes de cookies",
            copy    : "Selecciona una de las siguientes opciones y pulsa «Guardar ajustes» para confirmar.",
            options : {
                0 : "Desactivar todas las cookies de marketing, redes sociales y publicidad",
                1 : "Activar todas las cookies"
            },
            button : "Guardar ajustes"
        },
        toggle : "Ajustes de cookies"
    },
    fr : {
        disclaimer : {
            header : "Politique en matière de cookies",
            copy   : "En cliquant sur « J'accepte » ci-dessous, vous acceptez l'utilisation par notre société et par des tiers de cookies conçus pour améliorer le ciblage de la publicité personnalisée et le contenu sur nos sites Web et d'autres sites, ainsi que le traitement des données personnelles associées. Vous pouvez modifier vos préférences en matière de cookies en consultant la rubrique « Paramètres des cookies » ci-dessous. Consultez <a href='https://arena.net/en/legal/privacy-policy'>la Politique de confidentialité ArenaNet</a> pour plus d'informations sur nos pratiques en matière de confidentialité.",
            button : "J'accepte",
        },
        settings : {
            header  : "Paramètres des cookies",
            copy    : "Veuillez sélectionner l'une des deux options ci-dessous et appuyer sur le bouton « Enregistrer les paramètres » pour confirmer votre choix.",
            options : {
                0 : "Désactiver tous les cookies marketing, de réseaux sociaux et publicitaires",
                1 : "Activer tous les cookies"
            },
            button : "Enregistrer les paramètres"
        },
        toggle : "Paramètres des cookies"
    }
};
